
import * as Api from '../utils/api.js';
import React, { useEffect, useState, useCallback } from 'react';
import { getAuthorizationObj, deleteCookie, getCookie } from '../utils/useful-functions.js';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const MOBILE_VIEWPORT_THRESHOLD = 1024;

const Nav = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [storedUserPhone, setStoredUserPhone] = useState('');
  const [storedUserRole, setStoredUserRole] = useState('');
  const [displayValue, setDisplayValue] = useState('none');
  const [isActive, setIsActive] = useState(false); // 버거 메뉴 상태 추가

  const toggleBurgerMenu = () => {
    setIsActive(!isActive); // 클릭 시 상태 토글
  };


  const initFirebaseMessagingRegistration = useCallback((messaging) => {
    navigator.serviceWorker.ready.then((registration) => {
      getToken(messaging, { 
        vapidKey: 'BAJCK23mT4AgH4UcI8IoOmqXE6wmMNfdCGnvkJWK6T7lIskg5sQbuTY932F1uIJL54KqZTu3LWFM217h0vqybWM', 
        serviceWorkerRegistration: registration 
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log('FCM Token:', currentToken);
            localStorage.setItem('fcmToken', currentToken);
            sendTokenToServer(currentToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
    });
  }, []); // Empty dependency array to prevent unnecessary re-creation

  const fcmInit = useCallback(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyB7BqrBe14ExpJV5hyBR7Xd5gg0tN_KZ5w",
      authDomain: "yangsfarm-3a824.firebaseapp.com",
      projectId: "yangsfarm-3a824",
      storageBucket: "yangsfarm-3a824.appspot.com",
      messagingSenderId: "226385493837",
      appId: "1:226385493837:web:9cd17d3967ba1badc80104"
    };
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    initFirebaseMessagingRegistration(messaging);
    return messaging;
  }, [initFirebaseMessagingRegistration]); // Add initFirebaseMessagingRegistration as a dependency

  const sendTokenToServer = async (newToken) => {
    try {
      const result = await Api.getYesToken('/api/users?attribute=fcmToken');
      const currentFcmToken = result.data.fcmToken;

      if (newToken === currentFcmToken) {
        console.log('FCM DB Token == New Token');
      } else {
        await Api.patchYesToken('/api/users/fcmtoken', '', { fcmToken: newToken });
      }
    } catch (error) {
      console.error('FCM token update failed:', error.message);
      window.alert(`FCM 토큰 업데이트 실패: ${error.message}`);
    }
  };

  const fcmOnMessage = (messaging) => {
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon || '/img/mikkang-logo-192.png'
      };
      
      if (Notification.permission === 'granted') {
        new Notification(notificationTitle, notificationOptions);
      } else {
        const notificationElement = document.createElement('div');
        notificationElement.className = 'in-page-notification';
        notificationElement.innerHTML = `<h4>${notificationTitle}</h4><p>${notificationOptions.body}</p>`;
        document.body.appendChild(notificationElement);

        setTimeout(() => {
          document.body.removeChild(notificationElement);
        }, 5000);
      }
    });
  };

  const addNotificationStyles = () => {
    const style = document.createElement('style');
    style.innerHTML = `
      .in-page-notification {
        position: fixed;
        bottom: 10px;
        right: 10px;
        background: #444;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        z-index: 1000;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }

      .in-page-notification h4 {
        margin: 0;
        padding: 0;
        font-size: 16px;
      }

      .in-page-notification p {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
    `;
    document.head.appendChild(style);
  };

  const logOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessToken_exp');
    localStorage.removeItem('userPhone');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userName');
    localStorage.removeItem('companyId');
    localStorage.removeItem('fcmToken');
    localStorage.removeItem('nextDisplayDate');
    deleteCookie('refreshToken');
    deleteCookie('accessToken');
    window.location.href = '/login';
  };

  useEffect(() => {
    const { isLogin } = getAuthorizationObj();
    setIsLogin(isLogin);

    const kakologindata = getCookie('accessToken');
    if (kakologindata) {
      const accessToken = getCookie('accessToken');
      const exp = getCookie('accessToken_exp');
      const phoneArry2 = getCookie('phoneArry2');
      const userRole = getCookie('userRole');
      const userName = getCookie('userName');
      localStorage.setItem('accessToken_exp', exp);
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('userPhone', phoneArry2);
      localStorage.setItem('userRole', userRole);
      localStorage.setItem('userName', userName);
      deleteCookie('accessToken');
      deleteCookie('accessToken_exp');
      deleteCookie('phoneArry2');
      deleteCookie('userRole');
      deleteCookie('userName');
    }

    const storedUserPhone = localStorage.getItem('userPhone');
    const storedUserRole = localStorage.getItem('userRole');

    setStoredUserPhone(storedUserPhone);
    setStoredUserRole(storedUserRole);

    const handleResize = () => {
      setDisplayValue(window.innerWidth < MOBILE_VIEWPORT_THRESHOLD ? 'block' : 'none');
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    if ('serviceWorker' in navigator) {
      if (!navigator.serviceWorker.controller) {
        navigator.serviceWorker.register('/sw.js')
          .then(registration => console.log('Service Worker registered:', registration.scope))
          .catch(error => console.error('Service Worker registration failed:', error));
      }
      navigator.serviceWorker.ready.then(registration => {
        console.log('Service Worker is ready:', registration);
        if (isLogin) {
          const messaging = fcmInit();
          addNotificationStyles();
          fcmOnMessage(messaging);
        }
      });
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [fcmInit,isLogin]); // Add fcmInit to the dependency array

  return (
    <div
      className="navbar"
      style={{
        position: 'fixed',
        top: 0,
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backgroundImage: "url('/img/new_nav_background.webp')",
        backgroundRepeat: 'repeat',
        backgroundSize: 'auto',
        backgroundBlendMode: 'overlay'
      }}>
      <div className="container mt-3">
        <div className="navbar-brand" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <a className="navbar-item" href="/">
            <img style={{ width: '200px', maxHeight: '2.5em' }} src="/img/yangsfarm_logo.png" alt="LOGO" />
          </a>
          <div className="nav-mobile-only" style={{ display: displayValue }}>
            {isLogin ? (
              <a href="/mypage">
                <div className="button is-small is-success is-light" style={{ borderRadius: '20%', paddingLeft: '0.5em', paddingRight: '0.5em' }}>
                  {storedUserPhone}님
                </div>
              </a>
            ) : (
              <a href="/login">
                <div className="button is-small is-warning" style={{ borderRadius: '20%', paddingLeft: '0.5em', paddingRight: '0.5em' }}>로그인</div>
              </a>
            )}
          </div>
          <button
            className={`navbar-burger burger ${isActive ? 'is-active' : ''}`} // 상태에 따라 is-active 클래스 추가
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={toggleBurgerMenu} // 클릭 시 토글 함수 실행
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>
        <div id="navbarBasicExample" className={`navbar-menu ${isActive ? 'is-active' : ''}`} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
          <a className="navbar-item" href="/about"><span> About Us </span></a>
          <a className="navbar-item" href="/shop"><span> 바로 주문하기 </span></a>
          {isLogin && <a className="navbar-item" href="/myorder"><span> 내 주문내역 </span></a>}
          {isLogin ? (
            <>
              <div className="navbar-item has-dropdown is-hoverable" id="dropdown">
                <span className="material-icons navbar-link">account_circle</span>
                <div className="navbar-dropdown">
                  <a className="navbar-item" href="/myorder">내 주문내역</a>
                  <a className="navbar-item" href="/mypage">내 정보관리</a>
                  <button className="navbar-item logout" onClick={logOut}>로그 아웃</button>
                </div>
              </div>
              <div className="navbar-item button is-small is-success">{storedUserPhone}님</div>
              {storedUserRole === 'admin' || storedUserRole === 'seller' ? (
                <div className="navbar-item button is-small is-warning"><a href="/admin"> 관리(New) </a></div>
              ) : ''}
            </>
          ) : (
            <>
              <a className="navbar-item" href="/login"><span className="material-icons">account_circle</span></a>
              <div className="navbar-item button is-small is-warning"><a href="/login"> 로그인 </a></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Nav;

// import * as Api from '../utils/api.js';
// import React, { useEffect, useState, useCallback } from 'react';
// import { getAuthorizationObj, deleteCookie, getCookie } from '../utils/useful-functions.js';
// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// const MOBILE_VIEWPORT_THRESHOLD = 1024;

// const Nav = () => {
//   const [isLogin, setIsLogin] = useState(false);
//   const [storedUserPhone, setStoredUserPhone] = useState('');
//   const [storedUserRole, setStoredUserRole] = useState('');
//   const [displayValue, setDisplayValue] = useState('hidden');
//   const [isActive, setIsActive] = useState(false); // 버거 메뉴 상태 추가

//   const toggleBurgerMenu = () => {
//     setIsActive(!isActive); // 클릭 시 상태 토글
//   };

//   const initFirebaseMessagingRegistration = useCallback((messaging) => {
//     navigator.serviceWorker.ready.then((registration) => {
//       getToken(messaging, {
//         vapidKey: 'BAJCK23mT4AgH4UcI8IoOmqXE6wmMNfdCGnvkJWK6T7lIskg5sQbuTY932F1uIJL54KqZTu3LWFM217h0vqybWM',
//         serviceWorkerRegistration: registration,
//       })
//         .then((currentToken) => {
//           if (currentToken) {
//             console.log('FCM Token:', currentToken);
//             localStorage.setItem('fcmToken', currentToken);
//             sendTokenToServer(currentToken);
//           } else {
//             console.log('No registration token available. Request permission to generate one.');
//           }
//         })
//         .catch((err) => {
//           console.log('An error occurred while retrieving token. ', err);
//         });
//     });
//   }, []);

//   const fcmInit = useCallback(() => {
//     const firebaseConfig = {
//       apiKey: "AIzaSyB7BqrBe14ExpJV5hyBR7Xd5gg0tN_KZ5w",
//       authDomain: "yangsfarm-3a824.firebaseapp.com",
//       projectId: "yangsfarm-3a824",
//       storageBucket: "yangsfarm-3a824.appspot.com",
//       messagingSenderId: "226385493837",
//       appId: "1:226385493837:web:9cd17d3967ba1badc80104",
//     };
//     const app = initializeApp(firebaseConfig);
//     const messaging = getMessaging(app);
//     initFirebaseMessagingRegistration(messaging);
//     return messaging;
//   }, [initFirebaseMessagingRegistration]);

//   const sendTokenToServer = async (newToken) => {
//     try {
//       const result = await Api.getYesToken('/api/users?attribute=fcmToken');
//       const currentFcmToken = result.data.fcmToken;

//       if (newToken === currentFcmToken) {
//         console.log('FCM DB Token == New Token');
//       } else {
//         await Api.patchYesToken('/api/users/fcmtoken', '', { fcmToken: newToken });
//       }
//     } catch (error) {
//       console.error('FCM token update failed:', error.message);
//       window.alert(`FCM 토큰 업데이트 실패: ${error.message}`);
//     }
//   };

//   const fcmOnMessage = (messaging) => {
//     onMessage(messaging, (payload) => {
//       console.log("Message received. ", payload);
//       const notificationTitle = payload.notification.title;
//       const notificationOptions = {
//         body: payload.notification.body,
//         icon: payload.notification.icon || '/img/mikkang-logo-192.png',
//       };

//       if (Notification.permission === 'granted') {
//         new Notification(notificationTitle, notificationOptions);
//       } else {
//         const notificationElement = document.createElement('div');
//         notificationElement.className = 'in-page-notification';
//         notificationElement.innerHTML = `<h4>${notificationTitle}</h4><p>${notificationOptions.body}</p>`;
//         document.body.appendChild(notificationElement);

//         setTimeout(() => {
//           document.body.removeChild(notificationElement);
//         }, 5000);
//       }
//     });
//   };

//   const logOut = () => {
//     localStorage.removeItem('accessToken');
//     localStorage.removeItem('accessToken_exp');
//     localStorage.removeItem('userPhone');
//     localStorage.removeItem('userRole');
//     localStorage.removeItem('userName');
//     localStorage.removeItem('companyId');
//     localStorage.removeItem('fcmToken');
//     localStorage.removeItem('nextDisplayDate');
//     deleteCookie('refreshToken');
//     deleteCookie('accessToken');
//     window.location.href = '/login';
//   };

//   useEffect(() => {
//     const { isLogin } = getAuthorizationObj();
//     setIsLogin(isLogin);

//     const storedUserPhone = localStorage.getItem('userPhone');
//     const storedUserRole = localStorage.getItem('userRole');
//     setStoredUserPhone(storedUserPhone);
//     setStoredUserRole(storedUserRole);

//     const handleResize = () => {
//       setDisplayValue(window.innerWidth < MOBILE_VIEWPORT_THRESHOLD ? 'block' : 'none');
//     };

//     window.addEventListener('resize', handleResize);
//     handleResize();

//     if ('serviceWorker' in navigator) {
//       if (!navigator.serviceWorker.controller) {
//         navigator.serviceWorker.register('/sw.js')
//           .then((registration) => console.log('Service Worker registered:', registration.scope))
//           .catch((error) => console.error('Service Worker registration failed:', error));
//       }
//       navigator.serviceWorker.ready.then((registration) => {
//         console.log('Service Worker is ready:', registration);
//         if (isLogin) {
//           const messaging = fcmInit();
//           fcmOnMessage(messaging);
//         }
//       });
//     }

//     return () => window.removeEventListener('resize', handleResize);
//   }, [fcmInit, isLogin]);

//   return (
//     <div
//       className="fixed top-0 w-full bg-white/70 bg-cover h-16"
//       style={{ backgroundImage: "url('/img/new_nav_background.webp')" }}>
//       <div className="container mx-auto mt-3">
//         <div className="flex flex-row justify-between items-center">
//           <a href="/">
//             <img className="w-24 h-auto object-cover" src="/img/yangsfarm_logo.png" alt="LOGO" />
//           </a>
//           <div className={`block ${displayValue}`}>
//             {isLogin ? (
//               <a href="/mypage">
//                 <div className="text-sm text-success-light bg-success-light rounded-full px-2">
//                   {storedUserPhone}님
//                 </div>
//               </a>
//             ) : (
//               <a href="/login">
//                 <div className="text-sm text-yellow-600 bg-yellow-400 rounded-full px-2">로그인</div>
//               </a>
//             )}
//           </div>
//           <button
//             className={`navbar-burger ${isActive ? 'is-active' : ''} p-2`}
//             aria-label="menu"
//             aria-expanded="false"
//             onClick={toggleBurgerMenu}>
//             <span className="block w-6 h-1 bg-black mb-1"></span>
//             <span className="block w-6 h-1 bg-black mb-1"></span>
//             <span className="block w-6 h-1 bg-black"></span>
//           </button>
//         </div>
//         <div className={`flex flex-col lg:flex-row justify-end items-center ${isActive ? 'block' : 'hidden'}`}>
//           <a className="text-black p-2" href="/about">About Us</a>
//           <a className="text-black p-2" href="/shop">바로 주문하기</a>
//           {isLogin && <a className="text-black p-2" href="/myorder">내 주문내역</a>}
//           {isLogin ? (
//             <>
//               <div className="relative">
//                 <button className="text-black p-2">내 정보</button>
//                 <div className="absolute right-0 mt-2 bg-white shadow-md rounded p-2">
//                   <a className="block p-2" href="/myorder">내 주문내역</a>
//                   <a className="block p-2" href="/mypage">내 정보관리</a>
//                   <button className="block p-2" onClick={logOut}>로그 아웃</button>
//                 </div>
//               </div>
//               <div className="text-sm text-success px-2">{storedUserPhone}님</div>
//               {(storedUserRole === 'admin' || storedUserRole === 'seller') && (
//                 <div className="text-sm text-yellow-600 px-2">
//                   <a href="/admin">관리(New)</a>
//                 </div>
//               )}
//             </>
//           ) : (
//             <>
//               <a className="text-black p-2" href="/login">로그인</a>
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Nav;

